import { useMemo } from "react";
import { useEvents } from "./useEvents";

export interface EventFilters {
  first: number;
  after: string | null;
  month: number;
  year: number;
  latitude: number;
  longitude: number;
  radius: number;
  hidePastEvents: boolean;
}

export const useFilteredEvents = (filters: EventFilters) => {
  

  const startDate = useMemo(() => {
    const currentDate = new Date();
    if (filters.hidePastEvents) {
      if (filters.year < currentDate.getUTCFullYear() || (filters.year === currentDate.getUTCFullYear() && filters.month < currentDate.getUTCMonth())) {
        return new Date(0); // Past month, no events
      } else if (filters.year === currentDate.getUTCFullYear() && filters.month === currentDate.getUTCMonth()) {
        return new Date(Date.UTC(filters.year, filters.month, currentDate.getUTCDate())); // Current month, from current date
      }
    }
    return new Date(Date.UTC(filters.year, filters.month, 1)); // Future month or hidePastEvents is false
  }, [filters.year, filters.month, filters.hidePastEvents]);

  const endDate = useMemo(() => {
    if (startDate.getTime() === 0) {
      return new Date(0); // No events for past month
    }
    return new Date(Date.UTC(filters.year, filters.month + 1, 0));
  }, [filters.year, filters.month, startDate]);

  const { events, loading, error, refetch } = useEvents({
    first: filters.first,
    startDate: startDate,
    endDate: endDate,
    latitude: filters.latitude,
    longitude: filters.longitude,
    radius: filters.radius,
  });

  return { events, loading, error, refetch };
};