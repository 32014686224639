import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_EVENTS_IMAGES_QUERY } from "../api/eventService";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ErrorPanel from "../../../components/ErrorPanel";
import { SearchEventImagesResultModel } from "../models/searchEventImagesResultModel";

interface ImageSearchPanelProps {
  onSelectImage: (url: string) => void;
}

const ImageSearchPanel: React.FC<ImageSearchPanelProps> = ({
  onSelectImage,
}) => {
  const [query, setQuery] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const [searchImages, { data, loading, error }] = useLazyQuery(
    SEARCH_EVENTS_IMAGES_QUERY,
    {
      variables: { query },
    }
  );

  const handleSearch = async () => {
    if (query.trim() === "") return; // Avoid empty searches
    setHasSearched(true);
    searchImages({ variables: { query } });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      handleSearch();
    }
  };

  const handleSelectImage = (url: string) => {
    onSelectImage(url);
  };

  if (error) {
    return (
      <ErrorPanel message="Impossibile cercare immagini in questo momento, riprova più tardi." />
    );
  }

  // Generate placeholders
  const placeholders = Array.from({ length: 9 });

  return (
    <div>
      {/* Search Bar */}
      <div className="flex flex-row justify-between mb-4">
        <div className="relative w-full max-w-lg">
          <input
            name="search"
            type="search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ricerca immagini"
            className="block w-full pl-3 pr-10 py-2 rounded-md bg-white text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600"
          />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
      <MagnifyingGlassIcon
        aria-hidden="true"
        className="h-5 w-5 text-gray-400"
      />
    </div>
        </div>
        <button
          type="button"
          onClick={handleSearch}
          className="ml-3 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
        >
         Cerca
        </button>
      </div>

      {/* Image Grid */}
      <div className="grid grid-cols-3 gap-2 h-80 overflow-y-auto">
        {loading &&
          placeholders.map((_, index) => (
            <div
              key={index}
              className="bg-gray-200 animate-pulse h-24 w-full rounded"
            ></div>
          ))}
        {!hasSearched &&
          !loading &&
          placeholders.map((_, index) => (
            <div key={index} className="bg-gray-100 h-24 w-full rounded"></div>
          ))}

        {hasSearched &&
          !loading &&
          (data?.searchEventImages.length > 0 ? (
            data.searchEventImages.map(
              (image: SearchEventImagesResultModel) => (
                <div
                  key={image.url}
                  onClick={() => handleSelectImage(image.url)}
                >
                  <img
                    src={image.thumbnailUrl}
                    alt={image.url}
                    className="object-cover h-24 w-full rounded transition-transform duration-200 ease-in-out hover:scale-105 hover:opacity-80"
                    />
                </div>
              )
            )
          ) : (
            <div className="col-span-3 text-center text-gray-500">
              Nessuna immagine trovata.
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageSearchPanel;
