import { ArrowRight01Icon } from "hugeicons-react";
import { formatLongDateWithWeekDay, formatTime } from "../../../utility/date";
import { EventModel } from "../models/eventModel";
import { Link } from "react-router-dom";

export function DayEventsList({
  events,
  date,
}: {
  events: EventModel[];
  date: Date;
}) {
  return (
    <section className="mt-6">
      <h2 className="text-base font-semibold text-gray-900">
        Eventi di{" "}
        <time dateTime={date.toDateString()}>
          {formatLongDateWithWeekDay(date)}
        </time>
      </h2>
      <ol className="mt-4 flex flex-col gap-y-1 text-sm/6 text-gray-500 ">
        {events.map((event) => (
          <li
            key={event.id}
            className="group flex items-center gap-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <Link
              to={`/events/${event.id}?returnTo=${window.location.pathname}`}
              className="flex items-center gap-x-4 w-full"
            >
              <img
                src={event.images[0]}
                alt=""
                className="size-10 flex-none rounded-full"
              />
              <div className="flex-auto">
                <p className="font-bold text-gray-600 hover:underline">{event.title}</p>
                <p className="mt-0.5">
                  <time dateTime={event.date.toDateString()}>
                    {formatLongDateWithWeekDay(event.date)}{" "}
                    {formatTime(event.date)}
                  </time>{" "}
                  {event.endDate && (
                    <>
                      -{" "}
                      <time dateTime={event.endDate.toString()}>
                        {formatTime(event.endDate)}
                      </time>
                    </>
                  )}
                </p>
               
              </div>
              <ArrowRight01Icon className="w-4 h-4 text-gray-400 group-hover:text-gray-600" />
            </Link>
          </li>
        ))}
      </ol>
    </section>
  );
}
