import { useMutation } from "@apollo/client";
import { PencilIcon, ShareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SiWhatsapp } from "@icons-pack/react-simple-icons";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import AskToLoginPopup from "../../../components/AskToLoginPopup";
import ModalAlert from "../../../components/ModalAlert";
import Modal from "../../../components/ModalDialog";
import ThreeDotsMenu from "../../../components/ThreeDotsMenu";
import config from "../../../config/config";
import { LOGO_URL, SHARE_URL } from "../../../consts";
import useCachedProfile from "../../../hooks/useCachedProfile";
import { formatLongDateWithWeekDay, formatTime } from "../../../utility/date";
import { useAuth } from "../../auth/hooks/useAuth";
import { DELETE_EVENT_MUTATION } from "../api/eventService";
import { EventModel } from "../models/eventModel";
import { EventOwnerCard } from "./EventOwnerCard";
import EventProperties from "./EventProperties";
import EventSubscribeButton from "./EventSubscribeButton";
import EventSubscribers from "./EventSubscribers";

const urlRegex = /(https?:\/\/[^\s]+)/g;

const createLinkPreview = (url: string, key: string) => {
  // Basic styling for link preview
  return (
    <a
      key={key}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:underline break-words"
    >
      {url}
    </a>
  );
};

const EventCard = ({
  event,
  detailCard = false,
  refetch,
}: {
  event: EventModel;
  detailCard?: boolean;
  refetch?: () => void;
}) => {
  const { isAuthenticated } = useAuth();

  const { user } = useCachedProfile();
  const navigate = useNavigate();
  const [deleteEvent] = useMutation(DELETE_EVENT_MUTATION);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const handleDelete = async (eventId: string) => {
    try {
      if (!isAuthenticated) {
        setShowLoginPopup(true);
        return;
      }

      await deleteEvent({
        variables: { eventId },
      });
      refetch && refetch();
      //  navigate(`/events`);
    } catch (error) {
      console.error("Error removing event:", error);
    }
  };

  const [mapUrl, setMapUrl] = useState("");

  useEffect(() => {
    const updateMapUrl = () => {
      const screenWidth = window.innerWidth;

      const width = screenWidth - Math.round((screenWidth * 50) / 100); // Subtracting 100 for margin
      const height = Math.round((9 / 16) * width); // Calculating height for 16:9 aspect ratio

      const url = `https://maps.googleapis.com/maps/api/staticmap?center=${
        event.location.latitude
      },${
        event.location.longitude
      }&zoom=18&size=${width}x${height}&markers=icon:${encodeURIComponent(
        LOGO_URL
      )}%7C${event.location.latitude},${event.location.longitude}&key=${
        config.google.mapsApiKey
      }`;
      setMapUrl(url);
    };

    updateMapUrl();
    window.addEventListener("resize", updateMapUrl);

    return () => {
      window.removeEventListener("resize", updateMapUrl);
    };
  }, [event.location.latitude, event.location.longitude]);

  const handleShare = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Hey, dai un'occhiata a questo evento '${event.title}'`,
          url: `${SHARE_URL}/events/${event.id}`,
        });
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  const [openOwnerInfoDialog, setOpenOwnerInfoDialog] = useState(false);

  const closePopup = () => {
    setShowLoginPopup(false);
  };

  return (
    <div className="mx-auto w-auto overflow-hidden h-full flex flex-col max-h-130">
      {/* SEO */}
      <HelmetProvider>
        <div>
          <Helmet>
            <title>{event?.title || "Evento GioJoy"}</title>
            <meta name="description" content={event?.description || ""} />

            {/* Open Graph Meta Tags */}
            <meta
              property="og:title"
              content={event?.title || "Evento GioJoy"}
            />
            <meta
              property="og:description"
              content={event?.description || ""}
            />
            <meta property="og:image" content={event?.images[0] || LOGO_URL} />
            <meta
              property="og:url"
              content={`https://www.giojoy.com/events/${event?.id}`}
            />
            <meta property="og:type" content="website" />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={event?.title || "Evento GioJoy"}
            />
            <meta
              name="twitter:description"
              content={event?.description || ""}
            />
            <meta name="twitter:image" content={event?.images[0] || LOGO_URL} />
          </Helmet>
        </div>
      </HelmetProvider>
      <div className="relative">
        {event.owner != null && (
          <div
            className="absolute top-3 left-3 flex flex-row"
            onClick={() => setOpenOwnerInfoDialog(true)}
          >
            <img
              key={event.owner.id}
              src={event.owner.profileImage}
              alt={event.owner.name}
              className="w-12 h-12 rounded-full border-2 border-yellow-200 z-10 object-cover"
            />

            <Modal
              isOpen={openOwnerInfoDialog}
              onClose={() => setOpenOwnerInfoDialog(false)}
              title={""}
              onCancel={() => setOpenOwnerInfoDialog(false)}
              buttonLabel=""
            >
              <EventOwnerCard owner={event.owner} />
            </Modal>
          </div>
        )}
        {event.whatsappLink && (
          <a
            href={event.whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute bottom-3 left-3 bg-white p-2 rounded-full"
            title="Collegati al gruppo dell'evento su WhatsApp"
          >
            <SiWhatsapp color="#25D366" className="w-6 h-6" />
          </a>
        )}
        {event.owner != null && event.owner.id === user?.userId && (
          <div className="absolute top-3 right-3 flex">
            <ThreeDotsMenu
              title={"Cambia evento"}
              items={[
                {
                  title: "Modifica",
                  icon: (
                    <PencilIcon
                      aria-hidden="true"
                      className="mr-3 h-5 w-5 text-gray-400"
                    />
                  ),
                  onClick: () => {
                    navigate(`/events/${event.id}/edit`);
                  },
                },
                {
                  title: "Elimina",
                  icon: (
                    <XMarkIcon
                      aria-hidden="true"
                      className="mr-3 h-5 w-5 text-gray-400"
                    />
                  ),
                  onClick: async () => {
                    setOpenDeleteAlert(true);
                  },
                },
              ]}
            />
          </div>
        )}

        {detailCard ? (
          <Link to={`/events/${event.id}`}>
            <img
              src={event.images[0]}
              alt={event.title}
              className="w-full h-48 object-cover"
            />
          </Link>
        ) : (
          <img
            src={event.images[0]}
            alt={event.title}
            className="w-full h-48 object-cover"
          />
        )}
      </div>
      <div className="px-6 pt-4 pb-2">
        {event.tags.map((tag) => (
          <span
            key={tag}
            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
          >
            {"#" + tag.toLowerCase()}
          </span>
        ))}
      </div>

      {/* Event heading with text about allowed ages and optionally max capacity (if set) */}
      <div className="p-2 flex flex-col flex-grow">
        <h4 className="flex flex-row justify-between text-md  font-bold text-gray-500">
          <span>
            {" "}
            <time dateTime={formatLongDateWithWeekDay(event.date)}>
              {formatLongDateWithWeekDay(event.date)}{" "}
              {event.endDate ? "dalle" : "alle"} {formatTime(event.date)}{" "}
              {event.endDate && "alle"} {formatTime(event.endDate)}
            </time>
          </span>{" "}
        </h4>

        {/* Event title with or without link */}
        <div className="flex items-center justify-between">
          {detailCard ? (
            <Link to={`/events/${event.id}`} className="flex items-center">
              <h2 className="text-xl font-bold text-blue-600 hover:underline mr-4">
                {event.title}
              </h2>
            </Link>
          ) : (
            <h2 className="text-xl font-bold text-gray-800">{event.title}</h2>
          )}
          <div className="bg-blue-100 rounded-full p-2">
            <ShareIcon
              width={20}
              className="cursor-pointer text-blue-500"
              onClick={handleShare}
            />
          </div>
        </div>

        {/* Event description */}
        <p className="mt-3 text-base text-gray-700 h-auto md:overflow-auto md:max-h-48 md:min-h-48 mb-2">
          {event.description.split("\n").map((line, i) => {
            const parts = line.split(urlRegex);
            return (
              <React.Fragment key={`line-${i}`}>
                {parts.map((part, j) => {
                  const key = `part-${i}-${j}`;

                  if (part.match(urlRegex)) {
                    return createLinkPreview(part, key);
                  }
                  return <span key={key}>{part}</span>;
                })}
                <br />
              </React.Fragment>
            );
          })}
        </p>
        <div
          className={`${
            detailCard
              ? ""
              : "flex-col md:flex md:flex-row md:gap-4 md:items-center"
          }`}
        >
          {/* Event properties */}
          <div className={`${detailCard ? "w-full" : "w-full md:w-1/2"} mb-6`}>
            <EventProperties event={event} />
          </div>

          {/* Map */}
          <a
            href={`https://www.google.com/maps?q=${event.location.latitude},${event.location.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${
              detailCard ? "w-full" : "w-full md:w-1/2"
            } mt-4 md:mt-0`}
          >
            <img src={mapUrl} alt="Map preview" className="rounded-md w-full" />
          </a>
        </div>

        {/* Action items and subscribers */}
        <div className="flex items-center justify-between mt-4">
          {isAuthenticated ? (
            <EventSubscribers event={event} subscribers={event.subscribers} />
          ) : (
            <div className="flex -space-x-2 overflow-hidden mt-2">
              {/* Content shown if NOT authenticated */}
            </div>
          )}
          <EventSubscribeButton event={event} user={user} />
        </div>

        {/* Disclaimer */}
        <div className="mt-4"></div>
      </div>

      <ModalAlert
        isOpen={openDeleteAlert}
        onClose={() => setOpenDeleteAlert(false)}
        title={`Rimuovere l'evento "${event.title}"?`}
        message={`Sei sicuro di voler cancellare l'evento? L'operazione non è reversibile.`}
        actions={[
          {
            label: "Rimuovi",
            onClick: async () => {
              handleDelete(event.id);
            },
            color: "bg-red-600 hover:bg-red-700",
          },
        ]}
      />
      {showLoginPopup && <AskToLoginPopup closePopup={closePopup} />}
    </div>
  );
};

export default EventCard;
