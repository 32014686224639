import { useState } from "react";
import {
  Button,
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  MegaphoneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "./Logo";
import { publicNavigation, userNavigation } from "../consts";
import Footer from "./Footer";
import { useAuth } from "../features/auth/hooks/useAuth";
import { classNames } from "../features/common/helpers";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "../routes";
import useGetProfile from "../features/user/hooks/useGetProfile";

export default function PublicLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-white">
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <Logo />
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {publicNavigation.map((item) => {
              if (item.public || (!item.public && isAuthenticated)) {
                return (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    {item.name}
                  </a>
                );
              }
              return null;
            })}
          </div>

          <div className="flex flex-1 items-center justify-end gap-x-6">
            {isAuthenticated ? (
              <>
                <UserMenu />
              </>
            ) : (
              <>
                {" "}
                <a
                  href={LOGIN_ROUTE}
                  className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900"
                >
                  Accedi
                </a>
                <a
                  href={SIGNUP_ROUTE}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Registrati
                </a>
              </>
            )}
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center gap-x-6">
              <a href="/" className="-m-1.5 p-1.5">
                <Logo />
              </a>

              {isAuthenticated ? (
                <>
                  <UserMenu />
                </>
              ) : (
                <a
                  href={SIGNUP_ROUTE}
                  className="ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Registrati
                </a>
              )}

              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {publicNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {!isAuthenticated && (
                  <div className="py-6">
                    <a
                      href={LOGIN_ROUTE}
                      className="block w-full rounded-lg px-4 py-2.5 text-base font-semibold text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 text-center transition-colors duration-200"
                      >
                      Accedi
                    </a>
                  </div>
                )}

                {isAuthenticated &&
                  userNavigation.map((item) => (
                    <div key={item.name} className="py-6">
                      <a href={item.href}>{item.name}</a>
                    </div>
                  ))}
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <main className="flex-1 py-10">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
      <Footer />
    </div>
  );
}

function UserMenu() {
  const { user, loading } = useGetProfile();
  if (loading) {
    return null;
  }

  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  const handleShare = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Non sei ancora iscritto a GioJoy? Iscriviti ora!`,
          url: `${baseUrl}${SIGNUP_ROUTE}?ic=${user?.referralCode}`,
        });
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Web Share API not supported");
    }
  };

  return (
    <>
      <Button
        className="flex items-center  bg-orange-500 border-gray-100 border  rounded-lg text-white p-2"
        onClick={handleShare}
      >
        <MegaphoneIcon className="w-6 lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-white mr-3 " />
        <span className=""> Invita </span>
      </Button>
      <Menu as="div" className="relative">
        <MenuButton className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src={user?.profileImage}
            alt=""
          />
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              {user?.firstName}
            </span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </MenuButton>
        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          {userNavigation.map((item) => (
            <MenuItem key={item.name}>
              {({ active }) => (
                <a
                  href={item.href}
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block px-3 py-1 text-sm leading-6 text-gray-900"
                  )}
                >
                  {item.name}
                </a>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </>
  );
}
