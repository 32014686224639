import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { GET_EVENT_QUERY } from "../api/eventService";
import formatCurrency from "../../../utility/currencies";
import config from "../../../config/config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../components/StripeCheckoutForm";
import { useState } from "react";
import useCachedProfile from "../../../hooks/useCachedProfile";

enum PaymentMethods {
  CARD = "card",
  SATISPAY = "satispay",
}

function getAmount(data: any, userId: string) {
  const subscriber = data.event.subscribers.find(
    (sub: { id: string; userId: string; amount: number }) => sub.userId === userId
  );

  return subscriber ? subscriber.amount : 0;
}

// TODO: Get the amount to pay from the Subscriv
export default function Checkout() {
  const { eventId } = useParams<{ eventId: string }>();
  const location = useLocation();
  const { user } = useCachedProfile();

  const queryParams = new URLSearchParams(location.search);
  const clientSecret = queryParams.get("clientSecret");

  const { data, error, loading } = useQuery(GET_EVENT_QUERY, {
    variables: { id: eventId },
    fetchPolicy: "network-only",
  });
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.CARD
  );

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(config.stripe.publishableKey!);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error {error.message}</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <div className=" p-8 w-full max-w-5xl max-lg:max-w-xl mx-auto rounded-lg">
        <h1 className="text-3xl font-bold text-gray-800">Checkout</h1>
        <div className="grid lg:grid-cols-3 gap-6 max-lg:gap-8 mt-16">
          <div className="lg:col-span-2">
            <h3 className="text-xl font-bold text-gray-800">
              Scegli il metodo di pagamento
            </h3>

            <div className="grid gap-4 sm:grid-cols-2 mt-4">
              <div className="flex items-center">
                <input
                  type="radio"
                  className="w-5 h-5 cursor-pointer"
                  id="card"
                  onClick={() => setPaymentMethod(PaymentMethods.CARD)}
                  checked={paymentMethod === PaymentMethods.CARD}
                />
                <label
                  htmlFor="card"
                  className="ml-4 flex gap-2 cursor-pointer"
                >
                  <img
                    src="https://readymadeui.com/images/visa.webp"
                    className="w-12"
                    alt="card1"
                  />
                  <img
                    src="https://readymadeui.com/images/american-express.webp"
                    className="w-12"
                    alt="card2"
                  />
                  <img
                    src="https://readymadeui.com/images/master.webp"
                    className="w-12"
                    alt="card3"
                  />
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="radio"
                  className="w-5 h-5 cursor-pointer"
                  id="satispay"
                  onClick={() => setPaymentMethod(PaymentMethods.SATISPAY)}
                  checked={paymentMethod === PaymentMethods.SATISPAY}
                />
                <label
                  htmlFor="paypal"
                  className="ml-4 flex gap-2 cursor-pointer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/satispay-logo.png"}
                    className="w-20"
                    alt="paypalCard"
                  />
                </label>
              </div>
            </div>

            {paymentMethod === PaymentMethods.CARD && (
              <StripeCheckoutForm
                className="mt-8"
                clientSecret={clientSecret || ""}
                event={data.event}
              />
            )}

            {paymentMethod === PaymentMethods.SATISPAY && (
              <div className="flex items-center flex-col mt-8">
                <span className="text-gray-800 text-lg font-semibold">
                  Presto sarà possibile pagare anche con Satispay
                </span>
                <img
                  src={process.env.PUBLIC_URL + "/logo-satispay-rosso.png"}
                  className="w-60 ml-4  mt-8"
                  alt="satispay"
                />
              </div>
            )}
          </div>

          <div className="bg-white p-6 rounded-md max-lg:-order-1">
            <h3 className="text-lg font-bold text-gray-800">Riepilogo</h3>
            <ul className="text-gray-800 mt-6 space-y-3">
              <li className="flex flex-wrap gap-4 text-sm">
                Sub totale{" "}
                <span className="ml-auto font-bold">
                  {formatCurrency(
                    getAmount(data, user?.userId || ""),
                    "EUR",
                    "it"
                  )}{" "}
                </span>
              </li>
              <li className="flex flex-wrap gap-4 text-sm">
                Commissioni <span className="ml-auto font-bold">€ 0</span>
              </li>
              <hr />
              <li className="flex flex-wrap gap-4 text-base font-bold">
                Totale{" "}
                <span className="ml-auto">
                  {formatCurrency(
                    getAmount(data, user?.userId || ""),
                    "EUR",
                    "it"
                  )}{" "}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Elements>
  );
}
