import { ClockIcon } from "@heroicons/react/20/solid";
import { generateDaysForMonth } from "./utils/dateutils";
import { DayVM } from "./viewModels/dayVM";
import { EventModel } from "../models/eventModel";
import { useFilteredEvents } from "../hooks/useFilteredEvents";
import Loading from "../../../components/Loading";
import EventsFilterHeader from "../components/EventsFilterHeader";
import { classNames } from "../../common/helpers";
import ErrorPanel from "../../../components/ErrorPanel";
import { filtersVar } from "../api/eventsFilter";
import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { LOGO_URL } from "../../../consts";
import React, { useEffect } from "react";
import { DayEventsList } from "../components/DayEventsList";

interface DayDetails {
  date?: Date;
  events: EventModel[];
}

export default function Calendar() {
  const filters = useReactiveVar(filtersVar);
  const navigate = useNavigate();

  let days = generateDaysForMonth(filters.month, filters.year);
  const selectedDay = days.find((day) => day.isSelected);

  const { events, loading, error } = useFilteredEvents(filters);

  const [dayDetails, setDayDetails] = React.useState<DayDetails>({
    events: [],
  } as DayDetails);

  const enrichDaysWithEvents = (
    days: DayVM[],
    events: EventModel[]
  ): DayVM[] => {
    return days.map((day) => {
      const dayEvents = events.filter((event) => {
        const eventDate = new Date(event.date);
        const dayDate = new Date(day.date);

        return (
          eventDate.getFullYear() === dayDate.getFullYear() &&
          eventDate.getMonth() === dayDate.getMonth() &&
          eventDate.getDate() === dayDate.getDate()
        );
      });
      return {
        ...day,
        events: dayEvents,
      };
    });
  };

  days = enrichDaysWithEvents(days, events ?? []);

  useEffect(() => {
    // Reset dayDetails when filters change
    setDayDetails({ events: [] });
  }, [filters]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorPanel message="Riprova più tardi." />;
  }

  const getDayAbbreviations = () => {
    const formatter = new Intl.DateTimeFormat(undefined, { weekday: "short" });
    const days = [];
    for (let i = 1; i <= 7; i++) {
      const day = new Date(1970, 0, i + 4); // 1970-01-04 is a Sunday
      const shortDay = formatter.format(day);
      days.push(shortDay[0].toUpperCase() + shortDay.slice(1));
    }
    return days;
  };
  const dayAbbreviations = getDayAbbreviations();

  return (
    <div className="lg:flex lg:h-full lg:flex-col ">
      <EventsFilterHeader filters={filters} />

      <div className="">
        {dayDetails.events.length > 0 && dayDetails.date && (
          <DayEventsList events={dayDetails.events} date={dayDetails.date} />
        )}
      </div>

      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          {dayAbbreviations.map((day, index) => (
            <div key={index} className="bg-white py-2">
              {day[0]}
              <span className="sr-only sm:not-sr-only">{day.slice(1)}</span>
            </div>
          ))}
        </div>

        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {days.map((day) => (
              <button
                key={day.date.toDateString()}
                className={classNames(
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50 text-gray-500",
                  "relative px-3 py-2"
                )}
                onClick={() => {
                  if (day.events.length > 1) {
                    setDayDetails({ date: day.date, events: day.events });
                  } else if (day.events.length === 1) {
                    navigate(
                      `/events/${day.events[0].id}?returnTo=${window.location.pathname}`
                    );
                  } else {
                    setDayDetails({ date: day.date, events: [] });
                  }
                }}
              >
                <time
                  dateTime={day.date.toLocaleTimeString()}
                  className={
                    day.isToday
                      ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                      : undefined
                  }
                >
                  {day.date.getDate().toLocaleString()}
                </time>

                {day.events.length === 1 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse relative">
                    <div className="flex items-center gap-2 hover:text-indigo-600 relative">
                      <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-md font-medium text-gray-900  ring-gray-200">
                        <img
                          src={LOGO_URL}
                          alt={day.events[0].title}
                          className="h-4 w-4 rounded-full cursor-pointer"
                        />
                        {day.events[0].title}
                      </span>
                    </div>
                  </span>
                )}

                {day.events.length > 1 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse relative">
                    <div className="flex items-center gap-2 hover:text-indigo-600 relative">
                      <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-md font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                        <img
                          alt="logo"
                          src={LOGO_URL}
                          className="size-3 fill-yellow-500"
                        />
                        {day.events.length} eventi
                      </span>
                    </div>
                  </span>
                )}
              </button>
            ))}
          </div>

          {/* Mobile view */}
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.date.toDateString()}
                type="button"
                className={classNames(
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  day.isSelected || day.isToday ? "font-semibold" : "",
                  day.isSelected ? "text-white" : "",
                  !day.isSelected && day.isToday ? "text-indigo-600" : "",
                  !day.isSelected && day.isCurrentMonth && !day.isToday
                    ? "text-gray-900"
                    : "",
                  !day.isSelected && !day.isCurrentMonth && !day.isToday
                    ? "text-gray-500"
                    : "",
                  "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                )}
                onClick={() => {
                  if (day.events.length > 1) {
                    setDayDetails({ date: day.date, events: day.events });
                  } else if (day.events.length === 1) {
                    navigate(
                      `/events/${day.events[0].id}?returnTo=${window.location.pathname}`
                    );
                  } else {
                    setDayDetails({ date: day.date, events: [] });
                  }
                }}
              >
                <time
                  dateTime={day.date.toDateString()}
                  className={classNames(
                    day.isSelected
                      ? "flex h-6 w-6 items-center justify-center rounded-full"
                      : "",
                    day.isSelected && day.isToday ? "bg-indigo-600" : "",
                    day.isSelected && !day.isToday ? "bg-gray-900" : "",
                    "ml-auto"
                  )}
                >
                  {day.date.getDate().toLocaleString()}
                </time>
                <span className="sr-only">{day.events.length} events</span>
                {day.events.length === 1 && (
                  <img
                    src={LOGO_URL}
                    alt={day.events[0].title}
                    className="h-4 w-4 rounded-full cursor-pointer"
                  />
                )}

                {day.events.length > 1 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse relative">
                    <div className="flex items-center gap-2 hover:text-indigo-600 relative">
                      <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                        <img
                          alt="logo"
                          src={LOGO_URL}
                          className="size-1.5 fill-yellow-500"
                        />
                        {day.events.length}{" "}
                      </span>
                    </div>
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>

      {selectedDay && selectedDay?.events.length > 0 && (
        <div className="px-4 py-10 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDay.events.map((event) => (
              <li
                key={event.id}
                className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
              >
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">{event.title}</p>
                  <time
                    dateTime={event.date.toDateString()}
                    className="mt-2 flex items-center text-gray-700"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {event.date.toLocaleTimeString()}
                  </time>
                </div>
                <a
                  href={`/events/${event.id}`}
                  className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                >
                  Edit<span className="sr-only">, {event.title}</span>
                </a>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
