import React from 'react';

const Terms = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-gray-800">
      <h1 className="text-3xl font-bold text-center mb-8">Termini di Utilizzo di GioJoy</h1>

      <section className="mb-8">
        <p className="mb-4">
          Benvenuto su GioJoy! Utilizzando la nostra piattaforma, accetti i seguenti Termini di Utilizzo. 
          Ti invitiamo a leggerli attentamente.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">1. Definizioni</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>1.1 "Piattaforma": Si riferisce al sito web GioJoy (www.giojoy.com) e ai relativi servizi.</li>
          <li>1.2 "Utente": Qualsiasi persona che utilizza la Piattaforma, sia come creatore di eventi che come partecipante.</li>
          <li>1.3 "Eventi": Attività o incontri creati dagli utenti attraverso la Piattaforma.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">2. Accettazione dei Termini</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>2.1 L'accesso alla Piattaforma implica l'accettazione integrale dei presenti Termini di Utilizzo.</li>
          <li>2.2 Se non sei d'accordo con i Termini, ti preghiamo di non utilizzare GioJoy.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">3. Registrazione e Account Utente</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>3.1 Per creare o partecipare a eventi, è necessario registrarsi fornendo informazioni accurate e aggiornate.</li>
          <li>3.2 Gli utenti sono responsabili della riservatezza delle credenziali del proprio account.</li>
          <li>3.3 GioJoy si riserva il diritto di sospendere o eliminare account in caso di violazioni dei Termini.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">4. Creazione e Partecipazione agli Eventi</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>4.1 Gli utenti possono creare eventi conformi alle leggi vigenti e ai principi di rispetto reciproco.</li>
          <li>4.2 La partecipazione a un evento è soggetta alle regole definite dal creatore dell'evento.</li>
          <li>4.3 GioJoy non è responsabile per eventuali controversie o danni derivanti dalla partecipazione a eventi.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">5. Condotta degli Utenti</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>5.1 Gli utenti si impegnano a non:</li>
          <ul className="list-disc pl-8 mt-2">
            <li>Pubblicare contenuti illegali, offensivi o dannosi.</li>
            <li>Utilizzare la Piattaforma per scopi fraudolenti o non autorizzati.</li>
          </ul>
          <li>5.2 GioJoy si riserva il diritto di rimuovere contenuti e/o account che violano queste regole.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">6. Responsabilità di GioJoy</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>6.1 GioJoy agisce come intermediario tra gli utenti e non è responsabile per i contenuti o le attività pubblicate.</li>
          <li>6.2 GioJoy non garantisce il successo o la qualità degli eventi organizzati.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">7. Protezione dei Dati</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>7.1 I dati personali forniti dagli utenti saranno trattati in conformità alla nostra Informativa sulla Privacy.</li>
          <li>7.2 Gli utenti possono richiedere la modifica o cancellazione dei propri dati inviando una richiesta a info@little-endian.it o usando l'apposita funzione presente nella pagina del profilo utente.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">8. Modifiche ai Termini</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>8.1 GioJoy si riserva il diritto di modificare i Termini di Utilizzo in qualsiasi momento.</li>
          <li>8.2 Le modifiche saranno comunicate agli utenti attraverso la Piattaforma.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">9. Legge Applicabile e Foro Competente</h2>
        <ul className="list-decimal pl-6 space-y-2 mb-4">
          <li>9.1 I presenti Termini sono regolati dalla legge italiana.</li>
          <li>9.2 Per qualsiasi controversia, il foro competente è quello di Torino.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">10. Contatti</h2>
        <p className="mb-4">
          Per domande o segnalazioni, contattaci all'indirizzo email:{' '}
          <a href="mailto:info@little-endian.it" className="text-blue-600 hover:underline">
            info@little-endian.it
          </a>
        </p>
      </section>

      <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg mt-8">
        <p className="text-yellow-800 font-medium text-center">
          Grazie per aver scelto GioJoy!
        </p>
      </div>
    </div>
  );
};

export default Terms;