const faqs = [
  {
    question: "Posso modificare un evento dopo averlo pubblicato?",
    answer:
      "Si puoi, non è permesso modificare però la data e la possibilità di far partecipare figli. Questi due campi sono bloccati per garantire a chi si è iscritto che non cambino le condizioni a cui ha aderito.",
  },
  {
    question: "",
    answer:
      "",
  },
  {
    question: "Come si gestiscono gli eventi con posti limitati?",
    answer:
      "L'evento prevede un numero massimo di partecipanti, una volta raggiunto il limite non sarà più possibile iscriversi. Se un iscritto annulla la sua partecipazione il posto sarà disponibile per un altro utente.",
  },
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Domande frequenti di GioJoy
            </h2>
            <p className="mt-4 text-pretty text-base/7 text-gray-600">
              Non trovi le risposte che stai cercando contattaci su WhatsApp da
              qui{" "}
              <a
                href="email:info@giojoy.com"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                supporto WhatsApp di GioJoy
              </a>{" "}
              .
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
