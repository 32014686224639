import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import {
  AUTHENTICATE_MUTATION,
  CREATE_USER_MUTATION,
  REFRESH_TOKEN_MUTATION,
} from "../api/graphql/mutations";
import { jwtDecode } from "jwt-decode";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (
    username: string,
    password: string,
    rememberMe: boolean
  ) => Promise<void>;
  registerUser: (
    name: string,
    lastname: string,
    username: string,
    password: string,
    invitationCode: string,
    birthDate: Date,
    phoneNumber: string,
    gender: string,
    acceptTerms: boolean,
    acceptPrivacy: boolean
  ) => Promise<void>;
  logout: () => void;
  accessToken: string | null;
  loading: boolean;
}
interface AuthProviderProps {
  children: ReactNode;
  accessTokenKey: string;
  refreshTokenKey: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const isValidToken = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    // Ensure the decoded token has an 'exp' property and it's a number
    if (!decodedToken.exp || typeof decodedToken.exp !== "number") {
      return false;
    }

    return decodedToken.exp > currentTime;
  } catch (error) {
    console.error("Token validation error:", error);
    return false;
  }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  accessTokenKey,
  refreshTokenKey,
}) => {
  const [authenticate] = useMutation(AUTHENTICATE_MUTATION);
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [refreshTokenMutation] = useMutation(REFRESH_TOKEN_MUTATION);
  const [loading, setLoading] = useState(false);

  const storedToken =
    sessionStorage.getItem(accessTokenKey) ||
    localStorage.getItem(accessTokenKey);

  const tokenIsValid = isValidToken(storedToken);

  const [accessToken, setAccessToken] = useState<string | null>(
    tokenIsValid ? storedToken : null
  );
  const [isAuthenticated, setAuthenticated] = useState<boolean>(tokenIsValid);

  const login = async (
    username: string,
    password: string,
    rememberMe: boolean
  ) => {
    setLoading(true);
    try {
      const { data } = await authenticate({
        variables: { username, password },
      });
      const token = data.authenticate.accessToken;
      const refreshToken = data.authenticate.refreshToken;

      setAccessToken(token);
      setAuthenticated(true);

      if (rememberMe) {
        localStorage.setItem(accessTokenKey, token);
        localStorage.setItem(refreshTokenKey, refreshToken);
      } else {
        sessionStorage.setItem(accessTokenKey, token);
        sessionStorage.setItem(refreshTokenKey, refreshToken);
      }

      window.dispatchEvent(new Event("auth-change"));
    } finally {
      setLoading(false);
    }
  };

  const logout = useCallback(() => {
    // Prevent recursive calls
    if (!isAuthenticated && !accessToken) return;

    setAccessToken(null);
    setAuthenticated(false);

    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    sessionStorage.removeItem(accessTokenKey);
    sessionStorage.removeItem(refreshTokenKey);

    window.dispatchEvent(new Event("auth-change"));
  }, [accessTokenKey, refreshTokenKey, isAuthenticated, accessToken]);

  const refreshAccessToken = useCallback(async () => {
    const storedRefreshToken =
      sessionStorage.getItem(refreshTokenKey) ||
      localStorage.getItem(refreshTokenKey);

    if (!storedRefreshToken) {
      logout();
      return;
    }

    try {
      const { data } = await refreshTokenMutation({
        variables: { refreshToken: storedRefreshToken },
      });

      const newAccessToken = data.refreshToken.accessToken;
      const newRefreshToken = storedRefreshToken; // The call doesn't return a new refresh token

      setAccessToken(newAccessToken);
      setAuthenticated(true);

      // Update tokens in storage
      if (sessionStorage.getItem(accessTokenKey)) {
        sessionStorage.setItem(accessTokenKey, newAccessToken);
        sessionStorage.setItem(refreshTokenKey, newRefreshToken);
      } else if (localStorage.getItem(accessTokenKey)) {
        localStorage.setItem(accessTokenKey, newAccessToken);
        localStorage.setItem(refreshTokenKey, newRefreshToken);
      }

      window.dispatchEvent(new Event("auth-change"));
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      logout();
    }
  }, [accessTokenKey, logout, refreshTokenKey, refreshTokenMutation]);

  useEffect(() => {
    const checkToken = async () => {
      const token =
        sessionStorage.getItem(accessTokenKey) ||
        localStorage.getItem(accessTokenKey);

      if (token && isValidToken(token)) {
        setAccessToken(token);
        setAuthenticated(true);

        // Check if the token is about to expire
        const decodedToken: any = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeLeft = decodedToken.exp - currentTime;

        if (timeLeft < 300) {
          // Token expires in less than 5 minutes, refresh i
          console.log("Token is about to expire, refreshing...");
          await refreshAccessToken();
        }
      } else if (token) {
        // Token is invalid or expired, attempt to refresh
        console.log("Token is invalid or expired, attempting to refresh...");
        await refreshAccessToken();
      } else {
        // No token available, logout
        logout();
      }
    };

    checkToken(); // Initial check on component mount

    const interval = setInterval(checkToken, 60000); // Check every minute

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenKey, refreshAccessToken, refreshTokenKey]);

  useEffect(() => {
    const handleAuthChange = async () => {
      console.log("Auth change detected, checking token...");
      const token =
        sessionStorage.getItem(accessTokenKey) ||
        localStorage.getItem(accessTokenKey);

      if (!token) {
        // Only logout if we're currently authenticated
        if (isAuthenticated) {
          console.log("No token available, logging out...");
          setAccessToken(null);
          setAuthenticated(false);
        }
        return;
      }

      if (isValidToken(token)) {
        console.log("Token is fine, setting...");
        setAccessToken(token);
        setAuthenticated(true);
      } else {
        console.log("Token is invalid or expired, attempting to refresh...");
        await refreshAccessToken();
      }
    };

    window.addEventListener("auth-change", handleAuthChange);
    window.addEventListener("storage", handleAuthChange);

    return () => {
      window.removeEventListener("auth-change", handleAuthChange);
      window.removeEventListener("storage", handleAuthChange);
    };
  }, [
    accessTokenKey,
    logout,
    refreshAccessToken,
    refreshTokenKey,
    isAuthenticated,
  ]);

  const registerUser = async (
    name: string,
    lastname: string,
    username: string,
    password: string,
    invitationCode: string,
    birthDate: Date,
    phoneNumber: string,
    gender: string,
    acceptTerms: boolean,
    acceptPrivacy: boolean
  ) => {
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data } = await createUser({
        variables: {
          name,
          lastname,
          username,
          password,
          invitationCode,
          birthDate,
          phoneNumber,
          gender,
          acceptTerms,
          acceptPrivacy,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        registerUser,
        accessToken,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
