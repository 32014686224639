import { SiWhatsapp } from "@icons-pack/react-simple-icons";
import { OwnerUser } from "../models/eventModel";
import { EnvelopeIcon, StarIcon } from "@heroicons/react/24/outline";

export function EventOwnerCard({ owner }: { owner: OwnerUser }) {
  return (
    <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
      <div className="flex flex-1 flex-col p-8">
        <img
          alt=""
          src={owner.profileImage}
          className="mx-auto h-32 w-32 shrink-0 rounded-full object-cover"
        />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{owner.name}</h3>
        <dl className="mt-1 flex grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">Organizzatore</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <div className="inline-flex items-center">
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
            </div>
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <div
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-400 cursor-not-allowed"
              title="Coming soon"
            >
              <EnvelopeIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
              <span className="flex flex-col items-center">
                <span>Scrivi</span>
                <span className="text-xs italic">coming soon</span>
              </span>
            </div>
          </div>
          <div className="-ml-px flex w-0 flex-1">
            <div
              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-400 cursor-not-allowed"
              title="Coming soon"
            >
              <SiWhatsapp
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
              <span className="flex flex-col items-center">
                <span>Chatta</span>
                <span className="text-xs italic">coming soon</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
