import { useMutation } from "@apollo/client";
import formatCurrency from "../../../utility/currencies";
import { EventModel } from "../models/eventModel";
import { UNSUBSRIBE_EVENT_MUTATION } from "../api/eventService";
import { useState } from "react";
import AskToLoginPopup from "../../../components/AskToLoginPopup";
import SpinnerWithLabel from "../../../components/SpinnerWithLabel";
import EventPurchased from "./EventPurchased";
import Modal from "../../../components/ModalDialog";
import EventSubscribeDetails from "./EventSubscribeDetails";
import { useAuth } from "../../auth/hooks/useAuth";
import { UserModel } from "../../user/models/userModel";

const EventSubscribeButton = ({
  event,
  user,
}: {
  event: EventModel;
  user: UserModel | null;
}) => {
  const { isAuthenticated } = useAuth();

  const [unsubscribe, { loading: unsubscribing }] = useMutation(
    UNSUBSRIBE_EVENT_MUTATION,
    {
      update: (cache, { data: { unsubscribeEvent } }) => {
        if (!unsubscribeEvent) {
          // If the mutation returns false, exit early
          return;
        }

        // Identify the event in the cache
        const eventId = cache.identify({ __typename: "Event", id: event.id });

        // Modify the event in the cache
        cache.modify({
          id: eventId,
          fields: {
            subscribers(existingSubscriberRefs = [], { readField }) {
              // Find the subscriber to be removed
              const subscriberRef = existingSubscriberRefs.find((subscriberRef: any) => {
                const subscriberId = readField("userId", subscriberRef);
                return subscriberId === user?.userId;
              });

              // Calculate the total number of people to be removed
              const guests = readField("guests", subscriberRef) || 0;
              const childrenCount = readField("childrenCount", subscriberRef) || 0;
              const totalToRemove = 1 + Number(guests) + Number(childrenCount);

              // Update the remainingSeats field
              cache.modify({
                id: eventId,
                fields: {
                  remainingSeats(existingRemainingSeats) {
                    return existingRemainingSeats + totalToRemove;
                  },
                },
              });

              // Filter out the subscriber with the user's ID
              return existingSubscriberRefs.filter((subscriberRef: any) => {
                const subscriberId = readField("userId", subscriberRef);
                return subscriberId !== user?.userId;
              });
            },
            userSubscriptionStatus() {
              return "UNSUBSCRIBED";
            },
          },
        });
      },
    }
  );


  const [showPopup, setShowPopup] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openSubscribeDetails, setOpenSubscribeDetails] = useState(false);

  const onSubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!isAuthenticated) {
      setShowPopup(true);
      return;
    }

    setOpenSubscribeDetails(true);
  };

  const onUnsubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await unsubscribe({ variables: { eventId: event.id } });
    
    if(res.errors) {
      console.log(res.errors);
    }
  
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const renderButton = (
    event: EventModel,
    userSubscriptionStatus:
      | "SUBSCRIBED"
      | "UNSUBSCRIBED"
      | "PENDING"
      | "WAITING_LIST"
  ) => {
    if (event.date < new Date()) {
      return (
        <button className="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
          Evento passato
        </button>
      );
    }

    switch (userSubscriptionStatus) {
      case "SUBSCRIBED":
        return (
          <button
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={onUnsubscribe}
          >
            {unsubscribing ? (
              <SpinnerWithLabel label="Cancello ..." />
            ) : (
              "Cancella iscrizione"
            )}
          </button>
        );
      case "UNSUBSCRIBED":
        return event.price.amount === 0 ? (
          <button
            className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              event.maxSubscribers > 0 && event.remainingSeats === 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-500"
            }`}
            onClick={onSubscribe}
            disabled={event.maxSubscribers > 0 && event.remainingSeats === 0}
          >
            {`Iscriviti`}
            {event.maxSubscribers > 0 && event.remainingSeats >= 0 && (
              <>
                <br />
                {`(${event.remainingSeats} posti liberi)`}
              </>
            )}
          </button>
        ) : (
          <button
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onSubscribe}
          >
            Iscriviti a partire da{" "}
            {formatCurrency(event.price.amount, "EUR", "it-IT")}
          </button>
        );
      case "PENDING":
        return (
          <button
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={onUnsubscribe}
          >
            {unsubscribing ? (
              <SpinnerWithLabel label="Cancello ..." />
            ) : (
              "In attesa di pagamento, clicca per annullare"
            )}
          </button>
        );
      case "WAITING_LIST":
        return (
          <button
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={onUnsubscribe}
          >
            {unsubscribing ? (
              <SpinnerWithLabel label="Cancello ..." />
            ) : (
              "Sei in lista d'attesa, clicca per annullare"
            )}
          </button>
        );
    }
  };

  return (
    <>
      {renderButton(event, event.userSubscriptionStatus as any)}

      {showPopup && <AskToLoginPopup closePopup={closePopup} />}
      <EventPurchased
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        event={event}
      />
      <Modal
        title={`Iscrizione a  "${event.title}"`}
        buttonLabel=""
        isOpen={openSubscribeDetails}
        onCancel={() => {
          setOpenSubscribeDetails(false);
        //  refetch && refetch();
        }}
        onClose={() => {
          setOpenSubscribeDetails(false);
          //refetch && refetch();
        }}
      >
        {showPopup && <AskToLoginPopup closePopup={closePopup} />}

        <EventSubscribeDetails event={event} user={user} />
      </Modal>
    </>
  );
};

export default EventSubscribeButton;
