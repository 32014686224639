import { gql } from "@apollo/client";
import restClient from "../../../services/restClient";

export const GET_ME = gql`
  query GetMe {
    me {
      __typename
      userId
      profileImage
      firstName
      lastName
      birthDate
      gender
      email
      phoneNumber
      referralCode
      children {
        __typename
        childId
        firstName
        lastName
        birthDate
        gender
      }
      permissions {
        __typename
        canCreatePaidEvent
      }
      meta {
        __typename
        allowNewEventEmails
      }
    }
  }
`;
export const ADD_CHILD = gql`
  mutation AddChild(
    $firstName: String!
    $lastName: String!
    $birthDate: Time!
    $gender: Gender!
  ) {
    addChild(
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      gender: $gender
    )
  }
`;

export const DELETE_CHILD = gql`
  mutation RemoveChild($childId: ID!) {
    removeChild(childId: $childId)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $profileImage: String
    $firstName: String
    $lastName: String
    $birthDate: Time
    $phoneNumber: String
    $allowNewEventEmails: Boolean
  ) {
    updateUser(
      input: {
        profileImage: $profileImage
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        phoneNumber: $phoneNumber
        allowNewEventEmails: $allowNewEventEmails
      }
    )
  }
`;

/**
 * Used to upload User Profile image
 *
 * @param userId (not used, taken from auth token)
 * @param file
 */
export const uploadUserProfileImage = async (
  _: string,
  file: File
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await restClient.post(
      `/api/upload/users/profile-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status < 200 || response.status >= 300) {
      throw new Error("Failed to upload file");
    }

    const { destinationUrl } = response.data;
    return destinationUrl;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload file");
  }
};

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($confirmToken: String!) {
    confirmEmail(confirmToken: $confirmToken)
  }
`;

export const REQUEST_DELETE_ACCOUNT = gql`
  mutation RequestUserDeletion {
      requestUserDeletion
  }
`;