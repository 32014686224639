import { makeVar } from "@apollo/client";
import { EventFilters } from "../hooks/useFilteredEvents";
import { cities } from "../../../consts";

export const filtersVar = makeVar<EventFilters>({
  first: 100,
  after: null,
  month: 0,
  year: 0,
  latitude: 45.0703,
  longitude: 7.6869,
  radius: 250,
  hidePastEvents: true,
});

const now = new Date();

filtersVar({
  first: 100,
  after: null,
  month: now.getMonth(),
  year: now.getFullYear(),
  latitude: cities[0].latitude, // Using the first city (Turin) for now
  longitude: cities[0].longitude,
  radius: 250,
  hidePastEvents: true,
});
