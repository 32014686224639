import React from "react";
import Modal from "../../../components/ModalDialog";
import { formatDateStrToLocale } from "../../../utility/date";
import { SubscriberModel } from "../models/subscriberModel";
import { EventModel } from "../models/eventModel";

type SubscribersProps = {
  event: EventModel;
  subscribers: SubscriberModel[];
};



const maxVisibleSubscribers = 3;

const getTotalSubscribersCount = (subscribers: SubscriberModel[]) => {
  return subscribers.reduce((total, subscriber) => {
    return total + 1 + subscriber.guests + subscriber.childrenList.length;
  }, 0);
};

const EventSubscribers: React.FC<SubscribersProps> = ({
  event,
  subscribers,
}) => {
  const visibleParticipants = subscribers.slice(0, maxVisibleSubscribers);
  const hasMoreParticipants = subscribers.length > maxVisibleSubscribers;
  const [showSubscribersModel, setShowSubscribersModel] = React.useState(false);

  return (
    <>
      {subscribers.length > 0 ? (
        <>
          <div className="flex -space-x-2 overflow-hidden mt-2">
            <div className="flex -space-x-2 overflow-hidden mr-4">
              {visibleParticipants.map((participant) => (
                <img
                  key={participant.userId}
                  src={participant.profilePicture}
                  alt={participant.name}
                  className="w-8 h-8 rounded-full border-2 border-white"
                />
              ))}
              {hasMoreParticipants && (
                <div className="w-8 h-8 rounded-full border-2 border-white bg-gray-200 flex items-center justify-center text-gray-500">
                  +
                </div>
              )}
            </div>
            <span className=" text-gray-700 font-semibold">
              <button
                className="text-blue-600 hover:text-blue-800  focus:outline-none"
                onClick={() => setShowSubscribersModel(true)}
              >
                Vedi iscritti ({getTotalSubscribersCount(subscribers)})
               
              </button>
            </span>
          </div>
        </>
      ) : (
        <span className="text-gray-700 font-semibold">
          {event.date >= new Date() && "Iscriviti per primo!"}
        </span>
      )}
      <Modal
        title={`Iscritti all'evento (${getTotalSubscribersCount(subscribers)})`}
        buttonLabel=""
        isOpen={showSubscribersModel}
        onCancel={() => {
          setShowSubscribersModel(false);
        }}
        onClose={() => {
          setShowSubscribersModel(false);
        }}
      >
        <SubscribersList event={event} subscribers={subscribers} />
      </Modal>
    </>
  );
};

export default EventSubscribers;

function SubscribersList({ subscribers }: SubscribersProps) {
  return (
    <>
      <div className="max-h-96 overflow-y-auto">
        <ul className="divide-y divide-gray-200">
          {subscribers.map((subscriber) => (
            <li key={subscriber.id} className="flex items-center gap-x-6 py-5">
              <div className="flex min-w-0 w-full">
                <img
                  alt=""
                  src={subscriber.profilePicture}
                  className="size-12 flex-none rounded-full bg-gray-50 mr-2 object-cover"
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold text-gray-900">
                    {subscriber.name}
                  </p>
                  <p className="mt-1 truncate text-xs text-gray-500">
                    Iscritto il {formatDateStrToLocale(subscriber.timestamp)}
                  </p>
                  <p className="mt-1 truncate text-xs text-gray-500">
                    {subscriber.guests === 0 ? "nessun" : subscriber.guests}{" "}
                    ospit
                    {subscriber.guests <= 1 ? "e" : "i"} -{" "}
                    {subscriber.childrenCount === 0
                      ? "nessun"
                      : subscriber.childrenCount}{" "}
                    figliə
                  </p>
                </div>
                {subscriber.childrenList &&
                  subscriber.childrenList.length > 0 && (
                    <div className="ml-auto text-right">
                      <p className="text-sm font-semibold text-gray-900">
                        Figliə che partecipano
                      </p>
                      {subscriber.childrenList.map((child) => (
                        <div
                          key={child.childId}
                          className="text-xs text-gray-500"
                        >
                          {child.name} ({child.age} anni)
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
